const imagesLoaded = require('imagesloaded');

// Preload images
const preloadImages = () => {
    return new Promise((resolve) => {
        imagesLoaded(document.querySelectorAll("img"), resolve);
    });
};


export {
    preloadImages
};