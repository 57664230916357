import { header } from './modules/header';
import { animation } from './modules/animation';
// import { stalker } from './modules/stalker';

import { preloadImages } from './modules/utils';

// import Swiper from 'swiper/bundle';
// import 'swiper/swiper-bundle.css';

// const works = new Swiper(".j-works", {
//     speed: 400,
//     slidesPerView: 2.3,
//     spaceBetween: 80,
// });

// Preload images and fonts
Promise.all([preloadImages()]).then(() => {

    // remove loader (loading class)
    // stalker();

    header();
    animation();

    // window.addEventListener('resize', function(){
    //     window.location.reload();
    // });


});